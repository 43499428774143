import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CategoryTitle from "../components/index/categorytitle"
import AsideItem from "../components/aside/item"
import KimlikItem from "../components/kimlik/kimlikItem"
import amblem from '../download/maskot.pdf'
import kurumsalLogo from '../download/serenay_kurumsal.pdf' 
import kurumsalLogoLight from '../download/serenay_kurumsal_light.pdf' 


const Kimlik = () => (
	
  <Layout>
    <SEO title="Kimlik" />
    <div id="kurumsal" className="inlinePage footerFixed center-h">
      <div className="container-fluid w80 ma pt5 pb55 col-xs-pt15">
        <CategoryTitle title="SERENAY" subTitle="Kurumsal Kimlik" description="Kurumsal Kimlik" isDark={false} position="inline" />
        <div className="row">
        	<div className="w20 left pt15 mt15 col-sm-w100 pr45 col-sm-pr0">
        		<aside className="w100 left pos-r">
        			<ul className="w100 left fs14 fw6 col-xs-fs13" style={{letterSpacing: "0.5px"}}>
			        	<AsideItem to="/kurumsal" title="Bizim Hikayemiz" icon="icon-serenay" />
			        	<AsideItem to="/kimlik" title="Kurumsal Kimlik" icon="icon-kariyer1" />
			        	<AsideItem to="/gizlilik" title="Gizlilik Güvenlik" icon="icon-shield2" />
			        </ul>
        		</aside>
        	</div>
        	<div className="w80 col-sm-w100 left bl1 bc1 col-sm-bl0 col-sm-pl0 pt15 mt15 pl45 col-xs-mt0 col-xs-pt0">
        		<article className="w100 left p15 mb25 col-xs-pl0 col-xs-pr0">
        			<div className="w100 left flex">
        				<i className="icon icon-serenay fs20 colored2-bg white b-circle p15 left col-xs-back-none col-xs-colored2" style={{height:"52.11px"}}></i>
        				<span className="fw6 fs20 left p10 col-xs-fs16">Logo ve amblemlerimize ait proje dosyalarını indirebilir ve kullanım kurallarına uygun olarak kullanabilirsiniz.</span>
        			</div>        			
        			<span className="pl65 left mt10 col-xs-pl0">Logo ve amblem resmî internet sitemizde ilan edilen standartlara uygun olarak kullanılır. Markamızın logosunun ve ambleminin kullanım şekilleri ayrıntısıyla blirtilmiştir. Kurumsal olarak logo yada zemin rengimiz <b>"HEX :#000"</b> olarak belirlenmiştir. Logo ve amblem üzerinde bu standartlara aykırı hiçbir değişiklik yapılamaz.</span>
        		</article>
	        	<ul className="w100 left">
					<KimlikItem
						variant="normal"
	        			size="w50 col-xs-w100"
	        			backgroundClass="white "
	        			colorClass="colored3 "
	        			borderColor="bc1 "
	        			iconColor="colored2 "
	        			mintitle="KURUMSAL LOGO"
	        			linehex="#000"
	        			linergba="0,0,0,1"
	        			linehsla="0,0%,0%,1"
	        			florhex="#ffffff"
	        			florrgba="255,255,255,1"
	        			florhsla="0,0%,100%,1"
	        			download={kurumsalLogo}
	        		/>
					<KimlikItem
						variant="colored"
	        			size="w50 col-xs-w100"
	        			backgroundClass="colored2-bg "
	        			colorClass="white "
	        			borderColor="bc3 "
	        			iconColor="white "
	        			mintitle="KURUMSAL LOGO RENKLİ ZEMiN"
	        			linehex="#fff"
	        			linergba="255,255,255,1"
	        			linehsla="0,0%,100%,1"
	        			florhex="#000"
	        			florrgba="0,0,0,1"
	        			florhsla="0,0%,0%,1"
	        			download={kurumsalLogoLight}
	        		/>
	        		<li className="p15 left w100 col-xs-pl0 col-xs-pr0">
						<div className="box w100 p25 bs1 left b-ra5 b-white colored3">
							<div className="p25 w100 left b-ra5 bc1 flex col-xs-block" style={{border:"dashed 1px"}}>
								<div className="wi left col-xs-w100 col-xs-t-center">
									<i className="icon icon-serenay fs100 lh100 colored2"></i>
								</div>								
								<div className="info w100 left t-center fw4 pl25 col-xs-mt15 col-xs-pl0 center-h">
								<ul className="w100 left mb0 fs14 col-xs-t-center">
									<li className="wi left pl15 pr15 col-xs-w100 col-xs-pl0 col-xs-pr0 col-xs-mb15">
										<div className="title w100 left fw6 fs15">AMBLEM</div>
									</li>
									<li className="wi left t-left pl15 pr15 col-xs-w100 col-xs-pl0 col-xs-pr0">
										<span className="fw6 wi left mr10 col-xs-w100 col-xs-t-center">Çizgi Rengi :</span>
										<span className="fw4 wi left mr10 col-xs-w100 col-xs-t-center">HEX : #000</span>	        							
										<span className="fw4 wi left mr10 col-xs-w100 col-xs-t-center">RGBA : 0,0,0,1</span>	        							
										<span className="fw4 wi left mr10 col-xs-w100 col-xs-t-center">HSLA : 0,0%,0%,1</span>	        							
									</li>
									<li className="wi right col-xs-w100">
										<a className="col-xs-wi col-xs-table col-xs-ma col-xs-mb0 col-xs-mt15" target="_blank" rel="noopener noreferrer" href={amblem}>
											<i className="icon icon-download2 fs22 lh22 colored2"></i>
										</a>
									</li>
								</ul>
								</div>
							</div>
							
						</div>	        			
					</li>
	        	</ul>
	        </div>
        </div>        
      </div>
    </div>
  </Layout>
)

export default Kimlik
